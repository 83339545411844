
import { Connector } from "./Connector";
import { ITag, ITagTopMenu, ITagTopMenuWithoutContent } from "./Interfaces";

export const TagService = {
    async ListAsync(): Promise<ITag[]> {
        const data = await Connector.getAsync("tag")
        if (!data.ok) return [];
        return data.content
    },

    async ListNestedAsync(): Promise<ITagTopMenu[]> {
        const data = await Connector.getAsync("tag/nested")
        if (!data.ok) return [];
        return data.content
    },

    async ListTopMenuItemsAsync(): Promise<ITagTopMenuWithoutContent[]> {
        const data = await Connector.getAsync("tag/topmenu-items")
        if (!data.ok) return [];
        return data.content
    },

    async GetAsync(id: number): Promise<ITag | null> {
        const data = await Connector.getAsync(`tag/${id}`)
        if (!data.ok) return null;
        return data.content
    },

    async CreateAsync(name: string): Promise<number | null> {
        const data = await Connector.postAsync("tag", { name, sortOrder: 0, icon: "" });
        if (!data.ok) return null;
        return data.content.id;
    },

    async UpdateAsync(id: number, name: string, type: number | null, icon: string, sortOrder: number): Promise<number | null> {
        let obj: any = { name, icon, sortOrder }

        if (type) {
            obj = { ...obj, type }
        }

        const data = await Connector.putAsync(`tag/${id}`, obj)
        if (!data.ok) return null;
        return data.content.id
    },

    async DeleteAsync(id: number): Promise<boolean> {
        const data = await Connector.deleteAsync(`tag/${id}`);
        return data.ok
    },

    async MoveExercisesOnTagAsync(fromTagId: number, toTagId: number): Promise<boolean> {
        const data = await Connector.postAsync(`tag/move-exercises-on-tag`, { fromTagId, toTagId });
        return data.ok
    }
}