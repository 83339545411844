/** @format */

import { IoPricetagSharp, IoSaveOutline, IoTrashOutline } from "react-icons/io5";
import "./SingleTag.css";
import { ITag, ITagType } from "../../../Api/Interfaces";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { TagService } from "../../../Api/Tag";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { ArrowDown01, Image, SendToBack } from "lucide-react";
import { RequiredModal } from "../../../Shared/Modal/RequiredModal";
import { SelectType, UseSelect } from "../../../Shared/UseCustomerSelect";

export interface ISingleTagProps {
    tag: ITag;
    tagTypes: ITagType[];
    isSelected: boolean;
    changed: () => any;
    closeItem: () => any;
}

export const SingleTag = (props: ISingleTagProps) => {
    const [name, setName] = useState(props.tag.name);
    const [sortOrder, setSortOrder] = useState(props.tag.sortOrder);
    const [icon, setIcon] = useState(props.tag.icon);
    const [selectedType, setSelectedType] = useState(props.tag.type);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
    const tagSelect = UseSelect(SelectType.tag)

    useEffect(() => {
        setName(props.tag.name);
        setSortOrder(props.tag.sortOrder)
        setIcon(props.tag.icon)
        setSelectedType(props.tag.type);
    }, [props.isSelected]);

    const saveTag = async () => {
        await TagService.UpdateAsync(props.tag.id, name, selectedType?.id ?? null, icon ?? "", sortOrder ?? 0)
        props.changed()
    }

    const deleteTag = async () => {
        const shouldDelete = window.confirm("Er du sikker på at du vil slette taggen?");
        if (!shouldDelete) return;

        await TagService.DeleteAsync(props.tag.id);

        props.changed();
    }

    const removeTagType = async () => {
        setSelectedType(null);
    }

    const moveDeleteTag = async () => {
        const fromTagId = props.tag.id;
        const toTagId = tagSelect.selectedItem.id;
        if (fromTagId === toTagId) {
            alert("Kan ikke flytte til samme tagg. Velg en annen")
            return;
        }

        await TagService.MoveExercisesOnTagAsync(fromTagId, toTagId);
        await TagService.DeleteAsync(fromTagId);
        props.changed();
    }

    return (
        <div className="SingleTag customerItem">

            <RequiredModal isOpen={isMoveModalOpen} style={{ width: 500, height: "fit-content" }}>
                <div className="SingleTagMoveModal">

                    <h1>Sammenslå tagger</h1>
                    <p>Dette vil overføre alle øvelsene fra tagg {props.tag.name} til ny valgt tagg og slette tagg {props.tag.name}</p>
                    {tagSelect.component}
                    
                    <div className="cmd">
                        <p>Kommandoer</p>
                        <p>Flytt øvelser <b>{props.tag.name}{"->"}{tagSelect?.selectedItem?.name ?? "Ingen valgt"}</b></p>
                        <p>Slett tagg <b>{props.tag.name}</b></p>
                    </div>


                    <div className="btns">
                        <button onClick={() => setIsMoveModalOpen(false)} className="Button Primary" style={{ background: "var(--favorite-color)"}}>Avbryt</button>
                        <button disabled={tagSelect?.selectedItem?.id === undefined} onClick={moveDeleteTag} className="Button Primary">Flytt</button>
                    </div>
                </div>
            </RequiredModal>

            {props.isSelected ? (
                <div className="editable">
                    <div className="inputGroup" style={{ width: "100%" }}>
                        <input autoFocus required name="name" placeholder="Navn" value={name} onChange={(e) => setName(e.target.value)} />
                        <IoPricetagSharp />
                    </div>

                    <div className="inputGroup" style={{ width: "100%" }}>
                        <input required name="icon" placeholder="Ikon (svg kode)" value={icon} onChange={(e) => setIcon(e.target.value)} />
                        <Image /> 
                    </div>

                    <div className="inputGroup" style={{ width: "100%" }}>
                        <input type="number" required name="sortOrder" placeholder="Sortering (positivt tall)" value={sortOrder} onChange={(e) => setSortOrder(parseInt(e.target.value))} />
                        <ArrowDown01 />
                    </div>

                    <div className="selectWrapper">
                        <CreatableSelect
                            isClearable
                            options={props.tagTypes.map((t) => ({
                                label: t.name,
                                value: t.id.toString(),
                            }))}
                            onChange={(newValue: any) => {
                                const item = props.tagTypes.find((c) => c.id === parseInt(newValue.value));
                                setSelectedType(item || null);
                            }}
                            value={selectedType ? {

                                label: selectedType?.name,
                                value: selectedType?.id.toString(),
                            } : null}
                        />
                    </div>
                </div>
            ) : (
                <div className="viewDiv">
                    <div className="titleDiv">
                        <h2>{props.tag.name}</h2>
                        <div className="iconDiv" dangerouslySetInnerHTML={{ __html: props.tag.icon }} />
                    </div>

                    {props.tag.type?.name && <h3>{props.tag.type.name}</h3>}

                    <h3>sorting within column: <span style={{color: "#000" }}>{props.tag.sortOrder}</span></h3>
                </div>
            )}

            <div className="selected btnGroup" onClick={(e) => e.stopPropagation()} style={{ display: props.isSelected ? "flex" : "none", marginTop: 20 }}>
                <button onClick={removeTagType}>
                    <IoIosRemoveCircleOutline />
                    Fjern tagg-type
                </button>

                <button onClick={() => setIsMoveModalOpen(true)}>
                    <SendToBack size={14} style={{ color: "orange" }} />
                    Sammenslå
                </button>

                <button onClick={deleteTag}>
                    <IoTrashOutline style={{ color: "red" }} />
                    Slett
                </button>

                <button onClick={saveTag}>
                    <IoSaveOutline />
                    Large
                </button>
            </div>
        </div>
    );
};
