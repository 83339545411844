/** @format */

import "./UseModal.css";

export const RequiredModal = (props: any) => {
    return (
        <div className="UseModal" style={{ display: props.isOpen ? undefined : "none"  }}>
            <div className="innerModal" style={{ ...props.style }}>{props.children}</div>
        </div>
    );
};