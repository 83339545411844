/** @format */

import Fuse from "fuse.js";

export interface IGenericSearchItem<T> {
    items: T[];
    fieldsToSearch: Fuse.FuseOptionKey<T>[];
}

export class GenericSearchEngine<T> {
    private readonly fuse: Fuse<T>;
    private allItems: T[] = [];

    constructor(searchData: IGenericSearchItem<T>) {
        const options: Fuse.IFuseOptions<T> = {
            distance: 100,
            useExtendedSearch: true,
            keys: searchData.fieldsToSearch,
        };

        this.allItems = searchData.items;
        this.fuse = new Fuse(searchData.items, options);
    }

    public Search(text: string): T[] {
        if (text.trim() === "") {
            return this.allItems;
        }

        const fuseResult: any[] = this.fuse.search(text);
        console.log(JSON.stringify(fuseResult))

        const results = []
        for (var i = 0; i < fuseResult.length; i++) {
            results.push(fuseResult[i].item)
        }

        return results;
    }
}
