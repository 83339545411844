/** @format */

import { useEffect, useState } from "react";
import Select from "react-select";
import { CustomerService } from "../Api/Customer";
import { ICustomer } from "../Api/Interfaces";
import { TagService } from "../Api/Tag";

export enum SelectType {
    customer,
    tag,
}

export const UseSelect = (type: SelectType) => {
    const [items, setItems] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);

    const reloadAsync = async () => {
        if (type === SelectType.customer) {
            const data = await CustomerService.ListAsync();
            setItems(data);
        }

        if (type === SelectType.tag) {
            const data = await TagService.ListAsync();
            setItems(data);
        }

        setSelectedItem(null);
    };

    useEffect(() => {
        reloadAsync();
    }, []);

    const handleChangeAsync = (newValue: any) => {
        if (!newValue) return setSelectedItem(null);

        const customer = items.find((c) => c.id === parseInt(newValue.value));
        setSelectedItem(customer || null);
    };

    let selectedFormatted = null;
    if (selectedItem) {
        selectedFormatted = { label: selectedItem.name, value: selectedItem.id.toString() };
    }

    const component = (
        <Select
            classNamePrefix="react-select"
            styles={{ 
                container: (provided) => ({ ...provided, width: "100%" }) ,
                menuPortal: (base) => ({ ...base, zIndex: 1000 }),
            }}
            isClearable={true}
            placeholder={`Filtere på ${type === SelectType.customer ? "kunde" : "tagg"}`}
            noOptionsMessage={() => `Ingen ${type === SelectType.customer ? "kunder" : "tagger"} funnet`}
            value={selectedFormatted}
            options={items.map((c) => ({
                label: c.name,
                value: c.id.toString(),
            }))}
            onChange={handleChangeAsync}
            menuPortalTarget={document.body}
        />
    );

    const setCustomerByObject = (newCustomer: ICustomer): void => {
        setSelectedItem(newCustomer);
    };

    const setCustomerById = (customerId: number) => {
        const customer = items.find((c) => c.id === customerId);
        setSelectedItem(customer || null);
    };

    return {
        reloadAsync,
        items,
        selectedItem,
        component,
        setCustomerByObject,
        setCustomerById,
    };
};
