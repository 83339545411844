/** @format */

import { useEffect, useState } from "react";
import styles from "./TagMenu.module.css";
import { IBasicTag, ITagTopMenu, ITagTypeWithTags } from "../../../Api/Interfaces";
import { TagService } from "../../../Api/Tag";
import { IReactSet } from "../../../Shared/UseReactSet";
import { Filter, ListFilter, X } from "lucide-react";

export interface ITagMenuProps {
    selectedTags: IReactSet<IBasicTag>;
}

export const TagMenu = (props: ITagMenuProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedTopMenu, setSelectedTopMenu] = useState(0);
    const [data, setData] = useState<ITagTopMenu[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const nestedTags = await TagService.ListNestedAsync();
        setData(nestedTags);
    };

    const getCurrentMenuColumns = (): ITagTypeWithTags[] => {
        const dataset = data.filter((topMenu) => topMenu.id === selectedTopMenu);
        if (dataset.length === 0) return [];

        return dataset[0].columns;
    };

    const isTopMenuActive = (topMenu: ITagTopMenu): boolean => {
        for (const col of topMenu.columns) {
            for (const tag of col.tags) {
                if (props.selectedTags.contains(tag)) {
                    return true;
                }
            }
        }

        return false;
    };

    const menuColumns = getCurrentMenuColumns();

    return (
        <div
            className={styles.container}
            onMouseLeave={() => {
                setIsMenuOpen(false);
            }}
        >
            <div className={styles.menuItems}>
                <ul>
                    {data.map((topMenu) => (
                        <li
                            key={topMenu.id}
                            data-active-filter={isTopMenuActive(topMenu)}
                            data-selected={selectedTopMenu === topMenu.id && isMenuOpen}
                            onMouseOver={() => {
                                setIsMenuOpen(true);
                                setSelectedTopMenu(topMenu.id);
                            }}
                        >
                            <ListFilter size={12} />
                            {topMenu.text}
                        </li>
                    ))}
                </ul>

                <button
                    onClick={() => {
                        props.selectedTags.clear();
                    }}
                    data-visible={props.selectedTags.any()}
                    className={styles.clearFilterBtn}
                >
                    <X size={16} />
                </button>
            </div>

            <div className={styles.menuContentContainer} style={{ maxHeight: isMenuOpen ? "1000px" : 0 }}>
                <div className={styles.menuContent}>
                    {menuColumns.map((column) => (
                        <ul key={column.id}>
                            {column.tags.map((tag) => (
                                <li key={tag.id}>
                                    <button
                                        tabIndex={isMenuOpen ? undefined : -1}
                                        data-selected={props.selectedTags.contains(tag)}
                                        onClick={() => {
                                            const tags = props.selectedTags;
                                            if (tags.contains(tag)) {
                                                tags.remove(tag);
                                            } else {
                                                tags.add(tag);
                                            }
                                        }}
                                    >
                                        <div className={styles.dynamicIcon} dangerouslySetInnerHTML={{ __html: tag.icon }} />
                                        {tag.name}
                                        <div data-selected={props.selectedTags.contains(tag)} className={styles.selectedDot} />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
            </div>
        </div>
    );
};
