
import { Connector } from "./Connector";
import { ITag, ITagType } from "./Interfaces";

export const TagTypeService = {
    async ListAsync(): Promise<ITagType[]> {
        const data = await Connector.getAsync("tagtype")
        if (!data.ok) return [];
        return data.content
    },

    async GetAsync(id: number): Promise<ITagType | null> {
        const data = await Connector.getAsync(`tagtype/${id}`)
        if (!data.ok) return null;
        return data.content
    },

    async CreateAsync(name: string, topMenu: number): Promise<number | null> {
        const data = await Connector.postAsync("tagtype", { name, topMenu: topMenu, });
        if (!data.ok) return null;
        return data.content.id;
    },

    async UpdateAsync(id: number, newName: string, topMenu: number): Promise<number | null> {
        const data = await Connector.putAsync(`tagtype/${id}`, { name: newName, topMenu: topMenu, })
        if (!data.ok) return null;
        return data.content.id
    },

    async DeleteAsync(id: number): Promise<boolean> {
        const data = await Connector.deleteAsync(`tagtype/${id}`);
        return data.ok
    }
}