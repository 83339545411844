/** @format */

import { CircleAlert, Info } from "lucide-react";
import { PrettyDetails } from "../../../../Shared/PrettyDetails/PrettyDetails";
import styles from "../SettingSection.module.css";
import { useContext } from "react";
import { UserContext } from "../../../../App";

export const InfoZone = () => {
    return (
        <PrettyDetails text={"Informasjon"} icon={<Info />}>
            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>Vilkår og betingelser</h2>
                    <p>Informasjon vedrørende din avtale med Repset AS</p>
                </div>

                <a className={`${styles.ssettingsBtn}`} href="https://repset.no/vilkar-og-betingelser/" target="_blank" rel="noopener noreferrer">
                Vilkår
                </a>
            </div>

            <hr />

            <div className={styles.inputSection}>
                <div className={styles.infoText}>
                    <h2>OSS info</h2>
                    <p>Informasjon om open source software pakker</p>
                </div>

                <a className={`${styles.ssettingsBtn}`} href="/legal/oss-attribution.txt" target="_blank" rel="noopener noreferrer">
                    Attribution
                </a>
            </div>
        </PrettyDetails>
    );
};
