/** @format */

import { useEffect, useState } from "react";
import { IoPricetagSharp } from "react-icons/io5";
import { ITagTopMenuWithoutContent, ITagType } from "../../Api/Interfaces";
import { CrudList } from "../../Shared/CrudList/CrudList";
import { IItem } from "../../Shared/List/Interfaces";
import "./TagTypeView.css";
import { TagTypeService } from "../../Api/TagType";
import { TagService } from "../../Api/Tag";

export const TagTypeView = () => {
    const [tagTypes, setTagTypes] = useState<ITagType[]>([]);
    const [topMenuItems, setTopMenuItems] = useState<ITagTopMenuWithoutContent[]>([]);

    const updateTagTypes = async () => {
        const tags = await TagTypeService.ListAsync();
        setTagTypes(tags);
    };

    const updateTagTopMenu = async () => {
        const menus = await TagService.ListTopMenuItemsAsync();
        setTopMenuItems(menus);
    };

    useEffect(() => {
        updateTagTopMenu();
        updateTagTypes();
    }, []);

    const listCompatiableItems: IItem[] = [];
    tagTypes.forEach((tag) => {
        const item: IItem = {
            id: tag.id,
            name: tag.name,
            extraTextTop: tag.topMenu,
        };

        listCompatiableItems.push(item);
    });

    return (
        <div className="AdminListSimpleView">
            <div className="info">
                <h1>Tagg-typer</h1>
                <p>
                    <b>Vær forsiktig med og endre</b>, da dette vil føre til endring i produksjon. Bedre og lage en ny enn og redigere
                </p>
            </div>
            <CrudList
                crudComponent={(item) => (
                    <div>
                        <div className="inputGroup" style={{ width: "100%" }}>
                            <input autoFocus required name="name" placeholder="Navn" defaultValue={item?.name} />
                            <IoPricetagSharp />
                        </div>

                        <div style={{ width: "100%" }}>
                            <select defaultValue={item?.extraTextTop} name="topMenu">
                                {topMenuItems.map((menu) => (
                                    <option key={menu.id} value={menu.id}>{menu.text}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                onCreate={async (e) => {
                    await TagTypeService.CreateAsync(e.name.value, e.topMenu.value);
                    updateTagTypes();
                }}
                onEdit={async (e) => {
                    await TagTypeService.UpdateAsync(e.id.value, e.name.value, e.topMenu.value);
                    updateTagTypes();
                }}
                onDelete={async (id) => {
                    const confirmation = window.confirm(
                        "Sikker på at du vil slette tag-typen? Alle taggene som er tilkoblet denne typen vil havne under diverse kategorien."
                    );
                    if (confirmation) {
                        await TagTypeService.DeleteAsync(id);
                        updateTagTypes();
                    }
                }}
                items={listCompatiableItems}
            />
        </div>
    );
};
