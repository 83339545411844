/** @format */

import { useContext, useEffect, useRef, useState } from "react";
import styles from "./CustomerPlanList.module.css";
import { useNavigate } from "react-router-dom";
import { IoHeart, IoHeartOutline, IoSearch } from "react-icons/io5";
import { IVisiblePlan, StateManContext } from "../../../StateMan";
import { Dumbbell, X } from "lucide-react";
import { ICustomer } from "../../../../../Api/Interfaces";
import { GenericSearchEngine } from "../../../../../Shared/Search/GenericSearchEngine";

interface ICustomerPlanListProps {
    plans: IVisiblePlan[];
    customer?: ICustomer;
}

export const CustomerPlanList = (props: ICustomerPlanListProps) => {
    const [searchText, setSearchText] = useState("");
    const stateMan = useContext(StateManContext);
    const navigate = useNavigate();

    const planSearchEngine = new GenericSearchEngine<IVisiblePlan>({ items: props.plans, fieldsToSearch: ["name"] });
    const planSearchItems = planSearchEngine.Search(searchText);

    return (
        <div className={styles.container}>
            <button className={styles.closeBtn} onClick={() => navigate("/customers")}><X size={18} /></button>
            <h2>Planer på kunden</h2>

            <div className={styles.filtering}>
                <div className={`borderInputIcon ${styles.searchBox}`}>
                    <IoSearch />
                    <input autoFocus placeholder="Søk" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </div>

                <button className={`Button Small Secondary ${styles.createBtn}`} onClick={() => navigate(`/plans/create/${props.customer?.id}`)}>
                    Ny plan
                </button>
            </div>

            {props.plans.length === 0 && (
                <div className={styles.noPlans}>
                    <p>Ingen planer</p>
                </div>
            )}

            <ul>
                {planSearchItems.map((plan) => (
                    <li key={plan.id}>
                        <button className={styles.listBtn} onClick={() => navigate(`/plans/edit/${plan.id}`)}>
                            <div className={styles.left}>
                                <Dumbbell size={26} />
                                <p>{plan.name}</p>
                            </div>

                            <button className={styles.favBtn} onClick={(e) => {
                                e.stopPropagation();
                                stateMan?.changePlanFavorite(plan.id);
                            }}>
                                {plan.favorite ? <IoHeart color="var(--favorite-color)" /> : <IoHeartOutline />}
                            </button>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
