/** @format */

import { useEffect, useReducer, useRef, useState } from "react";
import { IoPricetagSharp, IoSearch } from "react-icons/io5";
import { ITag, ITagType } from "../../Api/Interfaces";
import { TagService } from "../../Api/Tag";
import { CrudList } from "../../Shared/CrudList/CrudList";
import { IItem } from "../../Shared/List/Interfaces";
import "./TagView.css";
import ReactSelect from "react-select";
import { useScreenSize } from "../../Shared/UseScreenSize";
import { ExpandList } from "../../Shared/ListFramework/ExpandList";
import { SingleTag } from "./SingleTag/SingleTag";
import { TagTypeService } from "../../Api/TagType";
import { ReactSelectable } from "../../Shared/ISelectable";
import { GenericSearchEngine } from "../../Shared/Search/GenericSearchEngine";

export const TagView = () => {
    const [tags, setTags] = useState<ITag[]>([]);
    const [tagTypes, setTagTypes] = useState<ITagType[]>([]);
    const [searchQuery, setSearchQuery] = useState("");

    const listRef = useRef(document.createElement("div"));
    const screenSize = useScreenSize();

    const updateTags = async () => {
        const tags = await TagService.ListAsync();
        setTags(tags);
    };

    const updateTagTypes = async () => {
        const tagTypes = await TagTypeService.ListAsync();
        setTagTypes(tagTypes);
    };

    useEffect(() => {
        updateTags();
        updateTagTypes();
    }, []);

    const listCompatiableItems: IItem[] = [];
    tags.forEach((tag) => {
        const item: IItem = {
            id: tag.id,
            name: tag.name,
        };

        listCompatiableItems.push(item);
    });

    // https://stackoverflow.com/questions/9175268/javascript-sort-function-sort-by-first-then-by-second
    const cmp = (a: number, b: number) => {
        if (a > b) return +1;
        if (a < b) return -1;
        return 0;
    };

    const listHeight = screenSize.height - (listRef?.current?.offsetTop ?? 0) - 10;

    const searchEngine = new GenericSearchEngine<ITag>({ items: tags, fieldsToSearch: ["name"] });
    let searchItems = searchEngine.Search(searchQuery);

    searchItems = searchItems.sort((a, b) => (a.type?.name ?? "").localeCompare(b.type?.name ?? "") || cmp(a.sortOrder, b.sortOrder));

    return (
        <div className="AdminListSimpleView TagView">
            <div className="info">
                <h1>Tagger</h1>
                <p>
                    <b>Vær forsiktig med og endre</b>, da dette vil føre til endring i produksjon. Bedre og lage en ny enn og redigere
                </p>
            </div>

            <div className="searchBox">
                <div className={`UseSearch`}>
                    <IoSearch />
                    <input
                        placeholder="Søk"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                </div>

                <button
                    className="Primary Button"
                    onClick={async () => {
                        const name = prompt("");
                        if (!name) return;
                        await TagService.CreateAsync(name);
                        updateTags();
                    }}
                >
                    Opprett
                </button>
            </div>

            <ExpandList
                customRef={listRef}
                customStyles={{ maxHeight: `${listHeight}px`, overflowY: "auto", height: "initial" }}
                items={searchItems}
                componentRendering={(t: ITag, isSelected, closeItem) => {
                    return <SingleTag tagTypes={tagTypes} isSelected={isSelected} closeItem={closeItem} changed={updateTags} tag={t} key={t.id} />;
                }}
            />
        </div>
    );
};
