/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./GlobalStyles/reference.css";
import { App } from "./App";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a6b06ab519f8989153081f6d46e995e5@o4508551321681920.ingest.de.sentry.io/4508551323844688",
  integrations: [],
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
