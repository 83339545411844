import { useState } from "react"
import styles from "./BenjiConsole.module.css"


export const BenjiConsole = () => {
    const [output, setOutput] = useState("");

    const appendLine = (line: string) => setOutput(output + "\n" + line)

    const runCommand = (command: string) => {
        appendLine(`> ${command}`)

        if (command === "crash") {
            throw new Error("crashing on purpose")
        }
    }

    return (
        <div className={styles.container}>
            <h1>Debugging console for Benji</h1>
            <form onSubmit={(e) => {
                e.preventDefault()
                const form: any = e.target;
                const cmd = form.commandInput.value;
                runCommand(cmd)
            }}>
                <input autoFocus type="text" name="commandInput" />
            </form>

            <textarea value={output} readOnly />
        </div>
    )
}