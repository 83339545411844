/** @format */

import { Connector } from "./Connector";
import { IAdminUser, ICustomPrice, IPaymentStatus, IUser, UserType } from "./Interfaces";

export const UserService = {
    async GetAsync(): Promise<IUser | null> {
        const data = await Connector.getAsync("user/whoami");
        if (!data.ok) return null;
        return data.content;
    },

    async CreateAsync(name: string, email: string, password: string): Promise<IUser | null> {
        const data = await Connector.postAsync("user/signup", { name, email, password });
        if (!data.ok) return null;

        return data.content;
    },

    async LoginAsync(email: string, password: string): Promise<IUser | null> {
        const data = await Connector.postAsync("user/login", { email, password });
        if (!data.ok) return null;
        return data.content;
    },

    async LogoutAsync(): Promise<void> {
        await Connector.getAsync("user/logout");
    },

    async ForgotPasswordAsync(email: string): Promise<void> {
        await Connector.postAsync("user/resetpassword/request", { email });
    },

    async ValidateEmailAsync(code: number): Promise<boolean> {
        const data = await Connector.postAsync("user/validateemail/confirm", { code });
        return data.ok;
    },

    async ResendValidateEmailAsync(): Promise<boolean> {
        const data = await Connector.getAsync("user/validateemail/resend");
        return data.ok;
    },

    async UpdateAsync(name: string, email: string, icon: string): Promise<boolean> {
        const data = await Connector.putAsync("user/update", { name, email, icon });
        return data.ok;
    },

    async PaymentGetCustomPriceAsync(): Promise<ICustomPrice | null> {
        const data = await Connector.getAsync("user/payment/customprice");
        if (!data.ok) return null;
        return data.content;
    },

    async PaymentCheckoutAsync(quantity: number, price: number): Promise<string> {
        const data = await Connector.postAsync("user/payment/checkout", { quantity, price });
        return data.content.checkoutId;
    },

    async PaymentCustomCheckoutAsync(): Promise<string> {
        const data = await Connector.getAsync("user/payment/customcheckout");
        return data.content.checkoutId;
    },

    async PaymentPortalAsync(): Promise<string> {
        const data = await Connector.getAsync("user/payment/portal");
        if (!data.ok) return "";
        return data.content.url;
    },

    async PaymentStatusCheckAsync(): Promise<IPaymentStatus> {
        const data = await Connector.getAsync("user/payment/needtopay");
        return data.content
    },

    async PaymentRegisterActive(): Promise<void> {
        await Connector.getAsync("user/payment/registeractive");
    },

    async AdminListAllUsersAsync(): Promise<IAdminUser[]> {
        const data = await Connector.getAsync("user/admin/allusers")
        if (!data.ok) return [];
        return data.content
    },

    async AdminSetTypeAsync(userId: number, type: UserType): Promise<boolean> {
        const data = await Connector.putAsync("user/admin/settype", { id: userId, type: type, })
        return data.ok;
    },

    async AdminSetCustomPriceAsync(userId: number, priceId: string): Promise<boolean> {
        const data = await Connector.putAsync("user/admin/customprice", { id: userId, priceid: priceId, })
        return data.ok;
    },

    async AdminSetQuantityAsync(userId: number, quantity: number): Promise<boolean> {
        const data = await Connector.putAsync("user/admin/setquantity", { id: userId, quantity, })
        return data.ok
    },

    async AdminLogInAsUserAsync(userId: number): Promise<boolean> {
        const data = await Connector.postAsync("user/admin/user-imitation", { userId: userId, })
        return data.ok
    },

    async AdminDeleteAsync(userId: number): Promise<boolean> {
        const data = await Connector.putAsync("user/admin/delete", { id: userId, })
        return data.ok;
    },

    async ChangePasswordAsync(oldPassword: string, newPassword: string): Promise<boolean> {
        const data = await Connector.putAsync("user/changepassword", { oldPassword, newPassword });
        return data.ok;
    },

    async DangerouslyDeleteUserAsync(password: string): Promise<boolean> {
        const data = await Connector.postAsync("user/delete", { password });
        return data.ok;
    },
};
