/** @format */

import { useRef, useState } from "react";
import { IPlanExerciseWithMedia } from "../PlanEdit";
import { PlanListVideo } from "../PlanView/PlanListVideo";
import { GiWeight } from "react-icons/gi";
import { IoRepeatOutline } from "react-icons/io5";
import { IoMdInformationCircle, IoMdTimer } from "react-icons/io";
import "./AddedExercise.css";
import { MdClose } from "react-icons/md";

export interface IAddedExercise {
    exercise: IPlanExerciseWithMedia;
    isFirstItem: boolean;
    onClick: (id: number) => void;
    onInfoClick: () => unknown;
    onRemove: (id: number) => void;
    onInfoChange: (id: number, infoType: string, info: string) => void;
    copyInfoClick: (id: number) => any;
}

export const AddedExercise = (props: IAddedExercise) => {
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);

    const onMouseEnter = () => {
        setVideoUrl(props.exercise.mainVideoUrl);
    };

    const onMouseLeave = () => {
        setVideoUrl(null);
    };

    const isVideoVisible = videoUrl !== null;

    return (
        <div
            className="AddedExercise"
            onClick={(e: any) => {
                props.onClick(props.exercise.id);
            }}
        >
            <div className="header">
                <h2>{props.exercise.name}</h2>

                <button
                    tabIndex={-1}
                    className="infoBtn floatingBtn"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onInfoClick();
                    }}
                >
                    <IoMdInformationCircle />
                </button>
            </div>

            <button
                tabIndex={-1}
                className="closeBtn"
                onClick={(e) => {
                    e.stopPropagation();
                    props.onRemove(props.exercise.id);
                }}
            >
                <MdClose />
            </button>

            <div className="content">
                <div className="img" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <img ref={imgRef} src={props.exercise.mainImageUrl} style={{ display: !isVideoVisible ? "block" : "none" }} alt="exercise" />
                    <PlanListVideo onLoaded={() => {}} visible={isVideoVisible} src={videoUrl ?? ""} />
                    <button
                        tabIndex={-1}
                        className="copyInfoBtn"
                        onClick={(e) => {
                            e.stopPropagation();
                            props.copyInfoClick(props.exercise.id);
                        }}
                    >
                        Kopier reps/sets
                    </button>
                </div>

                <div className="textboxes" onClick={(e) => e.stopPropagation()}>
                    <div className="inputGroup mini noBottom">
                        <input
                            onKeyDown={(e) => {
                                if (props.isFirstItem && e.key === "Tab" && e.shiftKey) {
                                    e.preventDefault();
                                }
                            }}
                            type="text"
                            maxLength={50}
                            placeholder="Sets"
                            value={props.exercise.sets}
                            onChange={(e) => props.onInfoChange(props.exercise.id, "sets", e.target.value)}
                        />
                        <IoMdTimer />
                    </div>

                    <div className="inputGroup mini noBottom">
                        <input
                            type="text"
                            maxLength={50}
                            placeholder="Reps"
                            value={props.exercise.reps}
                            onChange={(e) => props.onInfoChange(props.exercise.id, "reps", e.target.value)}
                        />
                        <IoRepeatOutline />
                    </div>

                    <div className="inputGroup mini noBottom">
                        <input
                            type="text"
                            maxLength={50}
                            placeholder="Vekt"
                            value={props.exercise.weight}
                            onChange={(e) => props.onInfoChange(props.exercise.id, "weight", e.target.value)}
                        />
                        <GiWeight />
                    </div>
                </div>
            </div>
        </div>
    );
};
