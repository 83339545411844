/** @format */

import { useEffect, useState } from "react";
import SpinnerImg from "./RepSetSpinner.svg";

export const UseLoading = () => {
    const [isLoading, setIsLoading] = useState(false);

    const startLoading = () => {
        setIsLoading(true);
    };

    const stopLoading = () => {
        setIsLoading(false);
    };

    const switchLoading = () => {
        setIsLoading(!isLoading);
    };

    const loadingComponent = isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <img width={28} height={28} src={SpinnerImg} alt="load" />
        </div>
    ) : (
        <></>
    );

    return {
        startLoading,
        stopLoading,
        switchLoading,
        isLoading,
        loadingComponent,
    };
};
