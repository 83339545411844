/** @format */

import { useState } from "react";

export interface IReactSet<T> {
    state: Set<T>;
    add: (item: T) => void;
    remove: (item: T) => void;
    contains: (item: T) => boolean;
    clear: () => void;
    any: () => boolean;
}

export const useReactSet = <T,>(): IReactSet<T> => {
    const [state, setState] = useState(new Set<T>());

    const add = (item: T) => {
        const setCopy = Array.from(new Set(state));
        setState(new Set([...setCopy, item]));
    };

    const remove = (item: T) => {
        state.delete(item);
        setState(new Set(state));
    };

    const contains = (item: T): boolean => {
        return state.has(item);
    };

    const clear = () => {
        setState(new Set());
    };

    const any = (): boolean => {
        return state.size > 0;
    };

    return {
        state,
        add,
        remove,
        contains,
        clear,
        any,
    };
};
