/** @format */

import { LegacyRef, memo, useEffect, useRef, useState } from "react";
import { IoHeart } from "react-icons/io5";
import "./PlanView.css";
import { FileRole } from "../../../Api/FileRoleEnum";
import { PlanListVideo } from "./PlanListVideo";
import { IExercise } from "../../../Api/Interfaces";
import { IoMdInformationCircle } from "react-icons/io";

export interface IPlanViewProps {
    exercise: IExerciseWithImage;
    setFavorite: (exerciseid: number, favorite: boolean) => void;
    onDoubleClick: () => void;
    onInfoClick: () => void;
    loadImage: boolean;
    refCustom: LegacyRef<HTMLDivElement>;
    index: number;
}

export interface IExerciseWithImage extends IExercise {
    mainImageUrl: string;
}

export const PlanView = memo(function (props: IPlanViewProps) {
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [isImageVisible, setIsImageVisible] = useState(true);

    const onMouseEnter = () => {
        const listVideo = props.exercise.files.find((f) => f.role === FileRole.listPreviewVideo);
        if (listVideo) {
            setVideoUrl(listVideo.publicUrl);
        }
    };

    const onMouseLeave = () => {
        setVideoUrl(null);
        setIsImageVisible(true);
    };

    const onVideoLoaded = () => {
        setIsImageVisible(false);
    };

    const isVideoVisible = videoUrl !== null;

    const imageUrl = props.loadImage ? props.exercise?.mainImageUrl : "";

    return (
        <div
            tabIndex={0}
            aria-colindex={props.index}
            ref={props.refCustom}
            className="PlanView"
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    props.onDoubleClick()
                }
            }}
            onDoubleClick={props.onDoubleClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <button
                tabIndex={-1}
                className={`heartBtn floatingBtn ${props.exercise.favorite ? "like" : ""}`}
                style={{ zIndex: props.exercise.favorite ? 4 : 2 }}
                onClick={(e) => {
                    e.stopPropagation();
                    props.setFavorite(props.exercise.id, !props.exercise.favorite);
                }}
            >
                <IoHeart />
            </button>

            <button
                tabIndex={-1}
                className="infoBtn floatingBtn"
                onClick={(e) => {
                    e.stopPropagation();
                    props.onInfoClick();
                }}
            >
                <IoMdInformationCircle />
            </button>

            <div className="contentBox" style={{ zIndex: videoUrl ? 1 : 3 }}>
                <img ref={imgRef} src={imageUrl} style={{ display: isImageVisible ? "block" : "none" }} alt="exercise" />
                <PlanListVideo onLoaded={onVideoLoaded} visible={isVideoVisible} src={videoUrl ?? ""} />
            </div>

            <div className="textBox">
                <h2>{props.exercise.name}</h2>
            </div>
        </div>
    );
});
